html {
  /* background-color: lightslategray; */
  background-color: #e6ecf0;
}

/* #FA4238 */
.MainContent {
  display: flex;
  width: -webkit-fill-available;
  margin: 0 auto;
  background-color: #f4f6fb;
  height: -webkit-fill-available;
  margin-left: 90px;
  height: 100%;
  min-height: 100vh;
}

.MainContent-header {
  width: 100%;
  margin: 0 30px;
}

.MainContent-branding {
  display: flex;
}
