body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  display: flex;
  font-family: 'Rubik', sans-serif;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.input-field .prefix.active {
  color: #0277BD !important;
}

.input-field .prefix {
  color: #d1d4d7 !important;

}

.input-field .active-color:focus {
    border-bottom: 1px solid #0277BD !important;
    box-shadow: 0 1px 0 0 #0277BD !important;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

html {
  /* background-color: lightslategray; */
  background-color: #e6ecf0;
}

/* #FA4238 */
.MainContent {
  display: flex;
  width: -webkit-fill-available;
  margin: 0 auto;
  background-color: #f4f6fb;
  height: -webkit-fill-available;
  margin-left: 90px;
  height: 100%;
  min-height: 100vh;
}

.MainContent-header {
  width: 100%;
  margin: 0 30px;
}

.MainContent-branding {
  display: flex;
}

.primary {
  background-color: #0277BD !important;
  color: #ffffff !important;
}

.ghostButton {
  border-color: #FFFFFF !important;
  border-width: 1px !important;
  border-radius: 2px !important;
  cursor: pointer !important;
  border-style: solid !important;
  margin-bottom: 20px;
  background: transparent !important;
}

.logoUtmBoss {
  -webkit-text-fill-color: #FFFFFF !important ;
}

.redHover i:hover {
  color: #fd402f
}

.transparentBlock {
  background-color: transparent !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.breadcrumb:before {
  color: #9e9e9e !important
}

.ghostButtonNoBorder {
  cursor: pointer !important;
  margin-bottom: 20px;
  background: transparent !important;
  box-shadow: none !important;
  color: #9e9e9e !important
}

.ghostButtonNoBorder:hover {
  background: transparent !important;
  box-shadow: none !important;
  color:#0277BD !important
}


.transparentButton {
  border: none !important;
  cursor: pointer !important;
  margin-bottom: 20px;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.transparentButton:hover {
  color: #0277BD !important;
}

.transparentButton#Delete:hover {
  color: #fd402f !important;
}

.greyColor {
  color: #d1d4d7 !important;
}

.secundary {
  background-color: #FFFFFF !important;
  color: #333 !important;
}

.underline {
  border-bottom-style: solid;
  border-bottom-width: thin;
  border-bottom-color: #fafbff;
  margin-right: 5%;
  margin-left: 5%;
  width: 90%;
  min-height: 75px;
}

.input-field .active .prefix.active {
  color: #0277BD !important;
}

.input-field .prefix {
  color: #d1d4d7 !important;

}

.input-field .active .active-color:focus {
    border-bottom: 1px solid #0277BD !important;
    box-shadow: 0 1px 0 0 #0277BD !important;
}

.primary-hover {
  background-color: #0277BD !important;
  color: #ffffff !important;
}

.MenuItem img {
  margin: auto;
  width: 30px;
  height: auto;

}

.menubar {
  margin: 20% 0;
}

.MenuItem i {
  color: #ffffff;
}

.MenuItem i:hover {
  -webkit-text-fill-color: #0277BD;
}

.MenuItem:hover {
  -webkit-text-fill-color: #0277BD;
  /* background-color: #123a56; */

  .MenuItem i {
   -webkit-text-fill-color: #0277BD;
  }
}

.activeMenuItem {
  -webkit-text-fill-color: #0277BD;
}

.activeMenuItem div {
    background-color: #384348 !important;
}

.activeSubMenuItem {
  -webkit-text-fill-color: #0277BD;
}

.activeSubMenuItem div {
  background-color: #fafbff;
}
.activeSubMenuItem div i {
-webkit-text-fill-color: #0277BD;
}

.MenuItem {
  cursor: pointer;
  margin-top: 20px;
  overflow: hidden;
  margin: 10px 0;
  background-color: #263238;
  padding: 5px 0;
}

.MenuItem:hover {
  /* background-color: #002b4c78; */
  position: relative;
  top: -10;
}

.menuItemText {
  color: #ffffff;
  margin-top: 5px;
}

.SubMenuItem {
  margin-bottom: 0 !important;
}

.SubMenuItem I {
  -webkit-text-fill-color: #ffffff;
}

.SubMenuItem:hover {
  -webkit-text-fill-color: #0277BD;
  background-color: #fafbff;
}

.SubMenuItem:hover i {
    -webkit-text-fill-color: #0277BD;
}

.SubNav {
  /* background-color: #FFF;
  height: 100vh;
  width: fit-content;
  max-width: 20%;
  float: left;
  display: inline-block;
  border-color: #efefef;
  border-right-style: solid;
  border-right-width: 1px; */
  width: 300px;
  opacity: 0.92;
  /* background-color: #1a415c; */
  background-color: #263238;
}

.SubNav h1 {
  font-size: 2em;
}
.subNavHeader {
  /* height: 100px; */
  border-color: #efefef;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  /* border-right-style: solid;
  border-right-width: 1px; */
  width: 300px;
  /* height: 150px; */
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  background-color: #263238;
  opacity: 0.92;
}

.subNavContent {
  margin: 20px 0;
}

.showLocation h1 {
  /* margin-top: 0; */
  color: #002b4c;
  word-break: break-word;

}

.SubNavItem-single {
  margin: 20px;
  text-align: left;
  padding: 0 20px;
  text-indent: 20px;
}
.SubNavItem-single a {
  color: #565656 !important;
}

.SubNavItem-single-header {
  font-weight: 600 ;
  margin: 20px;
  text-align: left;
  padding: 0 20px;
  color: #002b4c;
  text-decoration: none;
}

.SubNavItems a {
  color: #002b4c;
  text-decoration: none;

}

.mainContentUtm h2 {
  font-size: 2em;
}

.createNewIcon {
  -webkit-text-fill-color: #0177bd;
  font-size: 40px;
}

/* .createNewText {
  -webkit-text-fill-color: #FFFFFF;
}

.createNewText:hover {
  -webkit-text-fill-color: #0277BD;
} */

#createNew {
  border-radius: 5px;
   -webkit-transition: 0.1s linear;
   transition: 0.1s linear
}

#createNew p {
  color: #0177bd;
}
#createNew:hover p {
  color: #FFFFFF;
}
#createNew:hover i {
  color: #FFFFFF;
  -webkit-text-fill-color: #FFFFFF;
}

#createNew:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  cursor: pointer;
  border: 1px solid #0177bd;
  background-color: #0177bd !important;
}

.utmCard {
  cursor: pointer;
  overflow: hidden;
  word-break: break-word;
  border-radius: 5px;
  -webkit-transition: 0.1s linear;
  transition: 0.1s linear;
  min-height: 150px;
}

.utmCard:hover {
  -webkit-transform: scale(1.05);
}

.cardItem {
  margin: 15px 15px;
  padding: 15px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  box-sizing: border-box;
  box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.15);
  background-color: #FFFFFF;
}

.loginSignupCol {
  background-color: #fff;
  margin-top: 10%;
  border-radius: 5px;
  box-sizing: border-box;
  box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.15);
  padding-bottom: 25px !important;
  padding-top: 25px !important;
}

.loginSignupHeader {
  text-align: center;
  color: #002b4c;
}

.tab a {
  color: #002b4c !important;
}

.indicator{
  background-color: #002b4c !important;
}

.tabs .tab a:focus, .tabs .tab a:focus.active {
    background-color: #0277BD33 !important;
    outline: none;
}

.CampaignMenuItem {
  height: 1px;
  width: 260px;
  opacity: 0.18;
  background-color: #FFFFFF;

}

.campaignHeader {
  min-height: 225px;
  height: auto;
  border-radius: 5px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.07);
  background-color: #0277BD;
  color: #FFFFFF;
}

.campaignHeader div {
  word-break: break-word;
}

.dashboardMain {
  display: inline-block;
  width: -webkit-fill-available;
  margin: 0 0 0 90px;
  /* height: -webkit-fill-available; */
}

.dashboardHeader {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.utmGrid {
  padding: 0 20px;
}

.dashboardUtmGrid {
  padding: 0 20px;
  background: -webkit-linear-gradient(top, #0277BD 50%, #e6ecf0 50%);
  background: linear-gradient(to bottom, #0277BD 50%, #e6ecf0 50%);
}

.utmStats {
  height: auto;
  border-radius: 5px;
  box-shadow: 0 5px 14px 0px rgba(0, 0, 0, 0.1);
  background-color: white;
  color: grey;
  margin-bottom: 10px;
}

.profileSettingsCard {
  margin-bottom: 30px;
}

.pendingUser {
  background-color: #fafbff;
  opacity: 0.7;
}

.SearchBar {
  border: none;
}

.actionCard {
  background-color: transparent;
  border-style: none;
  color: #ffffffc4;
  min-height: 200px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  width: -webkit-fill-available;
}

.notify-background {
  background-color: #0277BD;
}

.notificationProgressBar {
  background: #ffffff !important;
}

.notify-delete {
  background: rgb(255, 166, 0) !important;
  opacity: 90;
}

.notify-error {
  background: rgb(255, 255, 255) !important;
  color: rgb(135, 0, 0) !important; 
  opacity: 90;
}

.notify-text {
  font-size: 18px !important;
  color: #263238;
  text-align: center;
}

.notify-errortext {
  font-size: 18px !important;
  color: rgb(135, 0, 0) !important; 
  text-align: center;
}

.notify-success {
  background: #ffffff !important;
  opacity: 90;
}

.builder {
    width: 30%;
    opacity: 0.92;
    /* background-color: #1a415c; */
    background-color: #263238;
    color: #FFFFFF;
    padding: 20px;
    min-width: 200px;
}

.builder input {
  color: #ffffff !important;
}

.utmListItem {
  border-radius: 5px;
  word-break: break-all;
}

.fullUTM {
  margin-bottom: 10px;
}

.fullUTMText:hover {
  cursor: pointer;
  font-weight: 500;
  color: #0277BD;
}

.noBottomMargin {
  margin-bottom: 0 !important;
}

.builderContent {
  /* display: flex; */
  width: -webkit-fill-available;
  margin: 0 auto;
  background-color: #263238;
  height: -webkit-fill-available;
  /* margin-left: 90px; */
  height: 100%;
  /* min-height: 100vh; */
  -webkit-animation: mymove 5s infinite; /* Safari 4.0 - 8.0 */
  animation: mymove 5s infinite;
  /* padding: 20px; */
  opacity: 0.92;
  -webkit-transition: 0.5s linear !important;
  transition: 0.5s linear !important
}

.utmPreview {
  /* margin-top: 20px; */
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes mymove {
  from {top: 0px;}
  to {top: 200px;}
}

/* Standard syntax */
@keyframes mymove {
  from {top: 0px;}
  to {top: 200px;}
}

.dropDownList {
  background-color: white;
  padding: 2%;
  color: #233843;
  cursor: pointer;
}

.dropDownList:hover {
  background-color: #0178bc;
  padding: 2%;
  color: #ffffff;
  cursor: pointer;
}

.autoInput {
    margin-top: 1rem !important;
    margin-right: 0.75rem !important;
    margin-bottom: 1rem !important;
    margin-left: 0.75rem !important;
    width: 95% !important;
}

.closeIcon {
  cursor: pointer;
  background: transparent !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.tabsourcemedium {
  margin-top: 20px;
}

.badgeItem {
  display: flex !important;
  color: black;
  -webkit-text-fill-color: black;
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
}

.templateButton {
  margin: 1% 1% !important;
}

.noPadding {
  padding: 0 !important;
}

.noMargin {
  margin: 0!important;
}

.paddingThirty {
  padding: 30px !important;
}

.headerBlock {
  height: 20%;
}

.fullWidth {
  width: -webkit-fill-available;
}
.navBase {
  background-color: #263238;
  height: 100vh;
  width: 90px;
  /* float: left; */
  /* display: block; */
  box-sizing: border-box;
  /* box-shadow: black 1px 0px 20px; */
  /* overflow: overlay; */
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 99;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 20px 0px;
}

.shadow {
  box-shadow: black 1px 0px 20px;
}

.logo {
  height: 100px;
}

.logo img {
  margin: auto;
  width: 60px;
  height: 100px;
}

.spinning {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 4vmin;
}

.navBase h5 {
  font-size: 12px;
  font-family: Roboto;
  /* word-break: break-all; */
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.ProfilePicDiv {
  width: auto;
  padding-bottom: 20%;
}

.ProfileImage {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: auto;
  border-style:solid;
  border-color: #8ab7be;
  border-width: 1px;
  /* width: 100px;
  height: 100px; */
}

.ProfileImageSmall {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin: auto;
  border-style:solid;
  border-color: #8ab7be;
  border-width: 1px; 
}

.userName {
  color: #ffffffb3;
  margin-top: 5px;
}

