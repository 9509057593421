.ProfilePicDiv {
  width: auto;
  padding-bottom: 20%;
}

.ProfileImage {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: auto;
  border-style:solid;
  border-color: #8ab7be;
  border-width: 1px;
  /* width: 100px;
  height: 100px; */
}

.ProfileImageSmall {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin: auto;
  border-style:solid;
  border-color: #8ab7be;
  border-width: 1px; 
}

.userName {
  color: #ffffffb3;
  margin-top: 5px;
}
