.navBase {
  background-color: #263238;
  height: 100vh;
  width: 90px;
  /* float: left; */
  /* display: block; */
  box-sizing: border-box;
  /* box-shadow: black 1px 0px 20px; */
  /* overflow: overlay; */
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 99;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 20px 0px;
}

.shadow {
  box-shadow: black 1px 0px 20px;
}

.logo {
  height: 100px;
}

.logo img {
  margin: auto;
  width: 60px;
  height: 100px;
}

.spinning {
  animation: App-logo-spin infinite 20s linear;
  height: 4vmin;
}

.navBase h5 {
  font-size: 12px;
  font-family: Roboto;
  /* word-break: break-all; */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
